<template>
  <div
    :style="section.styles.cssVariables.value"
    class="bg-(bg-default) text-(fg-default)"
    :class="[
      section.styles.spacerTop.value,
      section.styles.spacerBottom.value,
    ]"
  >
    <div class="container">
      <div
        class="w-full"
        :class="[
          section.styles.textAlignment.value,
        ]"
        v-html="sanitize(section.data?.value?.[section.lang.value]?.content)"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});
const section = usePageSection(props);
</script>
